.theme {
  background: #141526 !important;
}

/* Utimate pagination */

ul.pagination_ultimate div button:nth-last-child(1),
ul.pagination_ultimate div button:nth-last-child(2),
ul.pagination_ultimate div button:nth-child(1),
ul.pagination_ultimate div button:nth-child(2) {
  display: none !important;
}

ul.pagination_ultimate div {
  width: fit-content;
}

ul.pagination_ultimate div button {
  margin-left: 0.1975rem !important;

  padding: 0.625rem 0.95rem;
  color: #697a8d;
  background-color: #f0f2f4;
  border: 0px solid #d9dee3;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  border-radius: 0.375rem;
  line-height: 1;
  text-align: center;
  min-width: calc(2.1875rem + 0px);
}

.pagination_ultimate li {
  cursor: pointer;
}

/* end pagination */

@font-face {
  font-family: "Jost";
  src: url("../public/fonts/Jost-Regular.ttf");
}

@font-face {
  font-family: "Pangea";
  src: url("../public/fonts/PangeaAfrikanTextTrial-Regular.otf");
}

@font-face {
  font-family: "Rene";
  src: url("../public/fonts/Rene Bieder - Galano Classic DEMO.otf");
}

@font-face {
  font-family: "Poppins";
  src: url("../public/fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: "DMSans";
  src: url("../public/fonts/DMSans_18pt-Light.ttf");
}
