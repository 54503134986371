@media (min-width: 577px) and (max-width: 640px) {
  .hro {
    display: none !important;
  }
}

@media (max-width: 576px) {
  .itemsView {
    display: none !important;
  }
}

.haut {
  height: 169px !important;
}

.haut_thumbnail {
  height: 300px !important;
}

.slide___3-Nqo {
  width: 100% !important;
}

.svg-icon {
  color:greenyellow !important;
}
