@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@700&display=swap');


body {
  font-family: Trip Sans !important ;
  @apply
  bg-zinc-50/10
  text-zinc-900/80 
}
